import { forwardRef } from "react";
import styled from "styled-components";

const NameProfession = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  font-family: Source Code Pro;
  font-size: max(1rem, 2.1vmin);
  color: var(--color);

  @media (max-width: 900px) {
    font-size: 0.5rem;
  }

  @media (max-width: 450px) {
    font-size: 0.3rem;
  }
`;
const Home = forwardRef(function HomeRoot(props, ref) {
  return window.matchMedia("(max-width: 450px)").matches ? (
    <NameProfession {...props} ref={ref}>
      <pre>{`
   ▄████████ ███▄▄▄▄   ████████▄  ▄██   ▄    
  ███    ███ ███▀▀▀██▄ ███   ▀███ ███   ██▄  
  ███    ███ ███   ███ ███    ███ ███▄▄▄███  
  ███    ███ ███   ███ ███    ███ ▀▀▀▀▀▀███  
▀███████████ ███   ███ ███    ███ ▄██   ███  
  ███    ███ ███   ███ ███    ███ ███   ███  
  ███    ███ ███   ███ ███   ▄███ ███   ███  
  ███    █▀   ▀█   █▀  ████████▀   ▀█████▀   
                                            
        `}</pre>
      <pre>{`
 ▄█          ▄████████    ▄████████ 
 ███         ███    ███   ███    ███ 
 ███         ███    █▀    ███    █▀  
 ███        ▄███▄▄▄      ▄███▄▄▄     
 ███       ▀▀███▀▀▀     ▀▀███▀▀▀     
 ███         ███    █▄    ███    █▄  
 ███▌    ▄   ███    ███   ███    ███ 
 █████▄▄██   ██████████   ██████████ 
  ▀                                   
  `}</pre>
      <pre>{`
█████████╗   ████╗    ██╗     
██╔════██║   ████║    ██║     
█████╗ ██║   ████║    ██║     
██╔══╝ ██║   ████║    ██║     
██║    ╚██████╔██████████████╗
╚═╝     ╚═════╝╚══════╚══════╝
        `}</pre>
      <pre>{`
███████████████╗█████╗ ████████╗  ██╗
██╔════╚══██╔══██╔══████╔════██║ ██╔╝
███████╗  ██║  █████████║    █████╔╝ 
╚════██║  ██║  ██╔══████║    ██╔═██╗ 
███████║  ██║  ██║  ██╚████████║  ██╗
╚══════╝  ╚═╝  ╚═╝  ╚═╝╚═════╚═╝  ╚═╝
        `}</pre>
      <pre>{`

▓█████  ███▄    █   ▄████  ██▓ ███▄    █ ▓█████ ▓█████  ██▀███  
▓█   ▀  ██ ▀█   █  ██▒ ▀█▒▓██▒ ██ ▀█   █ ▓█   ▀ ▓█   ▀ ▓██ ▒ ██▒
▒███   ▓██  ▀█ ██▒▒██░▄▄▄░▒██▒▓██  ▀█ ██▒▒███   ▒███   ▓██ ░▄█ ▒
▒▓█  ▄ ▓██▒  ▐▌██▒░▓█  ██▓░██░▓██▒  ▐▌██▒▒▓█  ▄ ▒▓█  ▄ ▒██▀▀█▄  
░▒████▒▒██░   ▓██░░▒▓███▀▒░██░▒██░   ▓██░░▒████▒░▒████▒░██▓ ▒██▒
░░ ▒░ ░░ ▒░   ▒ ▒  ░▒   ▒ ░▓  ░ ▒░   ▒ ▒ ░░ ▒░ ░░░ ▒░ ░░ ▒▓ ░▒▓░
 ░ ░  ░░ ░░   ░ ▒░  ░   ░  ▒ ░░ ░░   ░ ▒░ ░ ░  ░ ░ ░  ░  ░▒ ░ ▒░
   ░      ░   ░ ░ ░ ░   ░  ▒ ░   ░   ░ ░    ░      ░     ░░   ░ 
   ░  ░         ░       ░  ░           ░    ░  ░   ░  ░   ░     
        `}</pre>
    </NameProfession>
  ) : (
    <NameProfession {...props} ref={ref}>
      <pre>{`
   ▄████████ ███▄▄▄▄   ████████▄  ▄██   ▄         ▄█          ▄████████    ▄████████ 
  ███    ███ ███▀▀▀██▄ ███   ▀███ ███   ██▄      ███         ███    ███   ███    ███ 
  ███    ███ ███   ███ ███    ███ ███▄▄▄███      ███         ███    █▀    ███    █▀  
  ███    ███ ███   ███ ███    ███ ▀▀▀▀▀▀███      ███        ▄███▄▄▄      ▄███▄▄▄     
▀███████████ ███   ███ ███    ███ ▄██   ███      ███       ▀▀███▀▀▀     ▀▀███▀▀▀     
  ███    ███ ███   ███ ███    ███ ███   ███      ███         ███    █▄    ███    █▄  
  ███    ███ ███   ███ ███   ▄███ ███   ███      ███▌    ▄   ███    ███   ███    ███ 
  ███    █▀   ▀█   █▀  ████████▀   ▀█████▀       █████▄▄██   ██████████   ██████████ 
                                                 ▀                                   
        `}</pre>
      <pre>{`
█████████╗   ████╗    ██╗         ███████████████╗█████╗ ████████╗  ██╗
██╔════██║   ████║    ██║         ██╔════╚══██╔══██╔══████╔════██║ ██╔╝
█████╗ ██║   ████║    ██║         ███████╗  ██║  █████████║    █████╔╝ 
██╔══╝ ██║   ████║    ██║         ╚════██║  ██║  ██╔══████║    ██╔═██╗ 
██║    ╚██████╔██████████████╗    ███████║  ██║  ██║  ██╚████████║  ██╗
╚═╝     ╚═════╝╚══════╚══════╝    ╚══════╝  ╚═╝  ╚═╝  ╚═╝╚═════╚═╝  ╚═╝
        `}</pre>
      <pre>{`
██████╗██████╗ ██████╗ ██████╗██████╗ █████╗███╗   ██████╗   ████████████████╗ 
██╔══████╔══████╔═══████╔════╝██╔══████╔══██████╗ ████████╗ ██████╔════██╔══██╗
██████╔██████╔██║   ████║  █████████╔█████████╔████╔████╔████╔███████╗ ██████╔╝
██╔═══╝██╔══████║   ████║   ████╔══████╔══████║╚██╔╝████║╚██╔╝████╔══╝ ██╔══██╗
██║    ██║  ██╚██████╔╚██████╔██║  ████║  ████║ ╚═╝ ████║ ╚═╝ ███████████║  ██║
╚═╝    ╚═╝  ╚═╝╚═════╝ ╚═════╝╚═╝  ╚═╚═╝  ╚═╚═╝     ╚═╚═╝     ╚═╚══════╚═╝  ╚═╝
        `}</pre>
    </NameProfession>
  );
});

export default Home;
