import { forwardRef } from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import { languageAtom } from "../atom/atom";
import AndyHead from "../components/AndyHead";

const Container = styled.div`
  width: 70%;
  margin-top: 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  min-height: max(fit-content, 100%);
  background-color: #05321e;

  @media (max-width: 450px) {
    margin-bottom: 100px;
  }
`;

const Career = styled.div`
  font-family: ${(props) =>
    props.locale === "Eng" ? "Minecraft" : "DOSGothic"};
  margin: 40px;
  padding-bottom: 20px;

  h1 {
    font-size: 2rem;
    line-height: ${(props) => (props.locale === "Eng" ? "2.2rem" : "2.7rem")};
    margin-top: 20px;
    letter
  }

  h2 {
    font-size: 1.5rem;
    line-height: ${(props) => (props.locale === "Eng" ? "1.7rem" : "2rem")};
  }

  h3 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  @media (max-width: 450px) {
    h1 {
      font-size: 1.7rem;
      line-height: 2rem;
      margin-top: 20px;
    }

    h2 {
      font-size: ${(props) => (props.locale === "Eng" ? "1.3rem" : "0.9rem")};
    }

    h3 {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
`;

const CV = forwardRef(function CVRoot(props, ref) {
  const [language] = useAtom(languageAtom);

  return (
    <Container ref={ref}>
      <AndyHead />
      {language === "Eng" ? (
        <Career locale={language}>
          <h1>Soongsil Univ.</h1>
          <h2>Global School of Media Graduated. BD</h2>
          <h3>4yrs</h3>
          <h3>2005 Mar - 2010 Feb</h3>

          <h1>ROK Army</h1>
          <h2>Lieutenant (ROTC)</h2>
          <h3>2yrs and 4months</h3>
          <h3>2010 Mar - 2012 Jun</h3>

          <h1>Samsung Electronics</h1>
          <h2>LTE Network Engineer</h2>
          <h3>6yrs</h3>
          <h3>2012 Jul - 2018 Jun</h3>

          <h1>AIDKOREA</h1>
          <h2>Full Stack Developer</h2>
          <h3>1yr</h3>
          <h3>2021 Jan - 2022 Jan</h3>

          <h1>Carbon Data Lab</h1>
          <h2>Full Stack Developer</h2>
          <h3>6months</h3>
          <h3>2022 Mar - 2022 Aug</h3>
        </Career>
      ) : (
        <Career locale={language}>
          <h1>숭실대학교</h1>
          <h2>미디어학부</h2>
          <h3>4년재 졸업</h3>
          <h3>2005년 3월 - 2010년 2월</h3>

          <h1>대한민국 육군</h1>
          <h2>ROTC장교 중위 전역</h2>
          <h3>2년 4개월</h3>
          <h3>2010년 3월 - 2012년 6월</h3>

          <h1>삼성전자</h1>
          <h2>LTE 네트워크 엔지니어</h2>
          <h3>6년</h3>
          <h3>2012년 7월 - 2018년 6월</h3>

          <h1>한국축산데이터</h1>
          <h2>Full Stack 개발자</h2>
          <h3>1년</h3>
          <h3>2021년 1월 - 2022년 1월</h3>

          <h1>카본데이터랩</h1>
          <h2>Full Stack 개발자/개발팀장</h2>
          <h3>6개월</h3>
          <h3>2022년 3월 - 2022년 8월</h3>
        </Career>
      )}
    </Container>
  );
});

export default CV;

// const PortraitASCii = styled.pre`
//   font-family: Source Code Pro;
//   font-size: 0.5rem;

//   @media (max-width: 450px) {
//     font-size: 0.4rem;
//   }
// `;
// const Portrait = () => (
//   <PortraitASCii>{`
//                           ....
//                         .........    .............
//                    ................::::^~~~!~^^~^^^^::........
//                 .............::^~~~77?JJY55YY?77!!!!^^^:::.........
//              .... .::^~!!7?JJY5P55GB####BGP555Y555YJ??77!~~^::........
//            .....^!J5BB####&&&&&&&&&&&#GGPGPPGGGGBGGP5PYY5P55Y?~:.........
//          .....~JPB&&&@@&&&&&&&&&&&&&&BBB######&##BGPPGGB#&&&&&GY7^::. ........
//         ....:~YP#&&&&&&&&&&&&&&&&&&###&#&&&&##&&&##&&@@&#&&&&&&&BJ!^:.:  ........
//        .....^J5B#&&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&&&&&&&&&&@B7!!^::::.....
//       ......:?B#&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#B&#&#&&&&&#GGP?JJ?!^:...
//      ..::.:!YB&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#G#&&&#&&&&&@@@@&@@&#BPJ~:.
//     ..:.:!P&@@&&&#&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&###&&@#&&&&&&&&&&&&&&&&##GJ!^.
//     ..:~5&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#&@@@&&@@@&&&&&&&&&&&&&&#BBY!~.
//     .:?B@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#BBGGBB#&@@&&&&&&&&&&&&#BY!^:
//     ^J&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#PY?7!~~77??YB&@&&&&&&&&&&&#BJ!^:
//    :7&@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&@&B&57!!~^^::::^^~Y#@@&&&&&&&&&@#P?~:.
//    ^5@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#B#P!!!~~^^::::^^^~75#@&&&&&&&&&&#Y~:.
//    ~G@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&BB#P7!~~~~~^:::::^^^^^!Y&@&&&&&&&&&57^.
//    !#@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#B#GJ7!~~~~~^^:::::^^^^^~~?#@&&&&&&&#P7^.
//    7&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&##B5?7!~~~~~~^^::.:::^^^^^~~?#@&&&&&&#5?^.
//    ~#@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&BPYJ?77!!~~~~~~^^::.::::^^^^^~~J#@&&&&&#PY^.
//    :P@&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#B5J?7!!!!~~~~~^^^::...::::^^^^~~!5#&&&&##PJ^
//     ?&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&#BPY?7!!~~~~~~~^^^^::......::^^^^~~~75&@&&&BP?:
//     ^G@&&&&&&&&&&&&&&&&&&&&&&&&&@&&&#BPY?77!!~~~~~~~^^^^^::........::^^^^^~~7G&&B#557.
//     .5@&&&&&&&&&&&&&&&&&&&&&&&&#BGBGGGPGGGP5Y?7!~~~^^^^:::..........::::^^^^~?##GGYJ^..
//      ?&&&&&&&&&&&&&&@#BPPPPPPPP555PPPGBB#######BP55J7~^^^::::......::::::^::^^5BB5P~.
//      ~B@&&&&&&&&&&&&G5YYYYYYYJYYYYYYYYYYY5PP5PPB####G5J!~~^^^::::::::^::^^~77?YGJPJ.
//     .!P#&&&&&&&&&&&GJJJJJJJJJJ?JJJJJJJJJJJ????JJY5PPGP5Y?7!~~^^^^^^^^~~!?5BGP5YJYY.
//    .7YYYP#&&#&&&&&#Y????????JJJJJYYYYYYYJJ?7!!~~!7?JYYYJJ??7!~~~~~~~!7JJYJ?!!!!7J:
//   .!YBBYJ5PBG&&&&&PJ???????JJJJJYYYY5555YJ?7!^^:::^!7??????77!~~~!!!777!~^^^^^^:.
//   :?5BJ?YYJJ5&&&&BJ????????JJJJYY5PPGGGBBGGP5J?7!~~~!77???77!~~~~!!!!!!~^^^^^^:
//   :J5J?JY5J?J#&@#J?????????JJYY5PGB###&##@@@@@Y?YPP7!!77?7!~^^^^~~!7???J??77!~:
//   :?JYGGPGB5?P&@G????????????JY5PPGBBGGGPGBBGJ^:7YPP!~!7?7!~:::^~~!?5B&@@Y?YB5:
//   .7JGBBB##P?Y&#5??????????????JJY555PP55YJ?7!!!~~~~~^~777!~:.:^^^!7YP5PJ~^~77:
//   .!?G###BY77JGPJ????????????7??????JJJJJJ??77!!!~~^^~!7777!^:::^^^~!77!!!~~^:.
//   .!?YPPBG7?7JYJ????????????777777!!!!777777!!!!~~~~~!777777~:.:^^^^~!!!!!~^^:.
//   .~JYJ?YBP77??????????????????777!!~~!!!!!77!!!~~~!77777??7!:.:^^^^^~!!~~^^^::
//    :7????YGJ??????????????JJJ???77!!~~~~~~~~~~~~~~!7777????7!^:.:^^^^^~~~~~^^::.
//    .~7JJ?JJJ??????????????JJJJ???77!!~~~~~~~~~~~~~!7??JJJ??7!^^.::^^^^^^^^^^^^:.
//     .!JJJJYJ???????????JJJJJJJJ??77!!~~~~~^~~~~~~~!?555YYJ?7!~^:.::^^^^^^^^^^^:
//      :?5YJYJ??????JJJJJJJJJJJJJJ??77!~~~~~^^^^^^~~YGP5J????7!~~^:::^^^^^^^^^^^.
//       .!5PYJJJ????JJJJJJJJJJJJJJ??77!!~~~~~~^^^^~JGP5YJ?777777!~^^::^^^^^^^^^:.
//         ~55JJJJJJJJJJJJJJJYJJJJJ??77!!~~~~~^^^^^7PBG55YJ??J55YJ?!~~^^^^^^^^^^:
//         .7YYYYJJJJJJJJJJJJJJJJJJJ??77!!!~~~~^^^!J5GGG5Y5G#BBGYY5PGY!^^^^^^^^:.
//          :7YYYYJJJJJJJJJJJJJJJJJJJ??777!!!~~~~~7?JY5PPPPPP5Y?77??7^^^^^^^^^^:
//           :7YY5YYYJJJJJJJJJJJJJJJJ????777!!!~!!!7?JJYYYJJ7!~^^^:^::^^^^^^^^:.
//            ^7Y555YYYYJJJJJJJJJJJJJJ??????777!!!!!77???7!!~^^^^^^^^^^^^^^^^:.
//             :!J5P5555YYYYJJJYYJJJJJJJJJ????777777????7!!~~^:^^^:^^^^^^^^^^:.
//              .~JPPP55555YYYYYYYYYYJJJJJJJ??????JYYYJJ?JJJ??7!^^!!~^~~^^^^:.
//               .^JPGGPP555555555YYYYJJJJJJJJJYYG###BBBBBBBBBBGYJYJJY5?~^^:
//                 :75GGGGPPP555555YYYYYJJJJ??JY5PPGBBB##BBGP5Y?7!7!~^~!~^:.
//                  .~JPBBGGPPPPPP5555YYYJJJJJJJYYY555PPGGPPY7!!!!!~^^^^~^.
//                    :~JPBBBGGPPPPPP555YYYJJJJYYYYYYY555P55555J?7~^^^^^:.
//                      :~?5GBBBGGPPPPP555YYYYYYYYYYYYYYYYYYJJ?7!~~^^^^:
//                        .^7YPBBBBGGGPPP555YYYYYYYYYYYYJJ?77!~~^^^^^^.
//                           .^7YPBBBBBGGPPP55YYYJJJJJ???77!!~~^^^^^:.
//                              :^7J5GGBBBBGGPP5YJJ???7777!!!~~~~^^:
//                                 .:~!?Y5PGGGGGPP55YYJJJJ???777~^:
//                                     ..:^~!7?JYYYJJ???7777!!~^.
//                                           ....
//       `}</PortraitASCii>
// );
