import { forwardRef } from "react";
import styled from "styled-components";
import { ReactComponent as ReactIcon } from "../assets/icons/icons8-react.svg";
import { ReactComponent as NextjsIcon } from "../assets/icons/icons8-nextjs.svg";
import { ReactComponent as ExpressIcon } from "../assets/icons/icons8-express.svg";
import { ReactComponent as DjangoIcon } from "../assets/icons/icons8-django.svg";
import { ReactComponent as FlaskIcon } from "../assets/icons/icons8-flask.svg";
import { ReactComponent as JavascriptIcon } from "../assets/icons/icons8-javascript.svg";
import { ReactComponent as TypescriptIcon } from "../assets/icons/icons8-typescript.svg";
import { ReactComponent as PythonIcon } from "../assets/icons/icons8-python.svg";
import { ReactComponent as GolangIcon } from "../assets/icons/icons8-golang.svg";
import { ReactComponent as CsharpIcon } from "../assets/icons/icons8-c-sharp.svg";
import { ReactComponent as PhpIcon } from "../assets/icons/icons8-php.svg";
import { ReactComponent as RecoilIcon } from "../assets/icons/icons8-recoil.svg";
import { ReactComponent as ReduxIcon } from "../assets/icons/icons8-redux.svg";
import { ReactComponent as ApolloIcon } from "../assets/icons/icons8-apollo.svg";
import { ReactComponent as GraphqlIcon } from "../assets/icons/icons8-graphql.svg";
import { ReactComponent as MysqlIcon } from "../assets/icons/icons8-mysql.svg";
import { ReactComponent as PostgresqlIcon } from "../assets/icons/icons8-postgresql.svg";
import { ReactComponent as DockerIcon } from "../assets/icons/icons8-docker.svg";
import { ReactComponent as KubernetesIcon } from "../assets/icons/icons8-kubernetes.svg";
import { ReactComponent as AwsIcon } from "../assets/icons/icons8-aws.svg";
import { ReactComponent as Css3Icon } from "../assets/icons/icons8-css3.svg";
import { ReactComponent as SassIcon } from "../assets/icons/icons8-sass.svg";
import { ReactComponent as TailwindIcon } from "../assets/icons/icons8-tailwind-css.svg";
import { ReactComponent as GithubIcon } from "../assets/icons/icons8-github.svg";

const Container = styled.div`
  width: 80%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Minecraft;
  min-height: max(fit-content, 100%);

  h1 {
    font-size: 3rem;
    margin-bottom: 50px;
  }

  margin-bottom: 50px;
`;

const StackSet = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: Minecraft;
  font-size: 1rem;
  gap: 100px;
`;

const Category = styled.div`
  > span {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Type = styled.div`
  margin: 10px;
  font-size: 1.2rem;
  line-height: 1.5rem;
`;

const SkillBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > span {
    margin-left: 10px;
  }

  > i {
    filter: sepia(200%) contrast(200%) hue-rotate(70deg);
    margin: 3px 0;
  }
`;

const skillSet = [
  {
    category: "Front-End",
    skills: [
      {
        type: "Library & Framework",
        details: [
          { name: "React", icon: <ReactIcon /> },
          { name: "NextJS", icon: <NextjsIcon /> },
        ],
      },
      {
        type: "Style",
        details: [
          { name: "CSS", icon: <Css3Icon /> },
          { name: "Sass", icon: <SassIcon /> },
          "Less",
          "Styled Component",
          { name: "Tailwind-css", icon: <TailwindIcon /> },
        ],
      },
      {
        type: "UI",
        details: ["Material UI", "Rsuite", "Reactstrap"],
      },
      {
        type: "Form",
        details: ["React Hook Form", "Formik"],
      },
      {
        type: "State Management",
        details: [
          { name: "Redux", icon: <ReduxIcon /> },
          { name: "Recoil", icon: <RecoilIcon /> },
          "Jotai",
        ],
      },
      {
        type: "Communication",
        details: [
          "Axios",
          "Axios Hook",
          "Socket.io",
          { name: "GraphQL", icon: <GraphqlIcon /> },
          { name: "Apollo Client", icon: <ApolloIcon /> },
        ],
      },
      {
        type: "Analytics",
        details: ["Google Analytics", "Google Tag Manager"],
      },
      {
        type: "3D Graphics",
        details: ["Threejs", "React-three-fiber"],
      },
      {
        type: "Hybrid Mobile Framework",
        details: [{ name: "React Native", icon: <ReactIcon /> }, "Flutter"],
      },
      {
        type: "ETC",
        details: ["P5js", "ml5js"],
      },
    ],
  },
  {
    category: "Back-End",
    skills: [
      {
        type: "Computer Language",
        details: [
          { name: "JavaScript", icon: <JavascriptIcon /> },
          { name: "TypeScript", icon: <TypescriptIcon /> },
          { name: "Python", icon: <PythonIcon /> },
          { name: "PHP", icon: <PhpIcon /> },
          { name: "Go", icon: <GolangIcon /> },
          { name: "C#", icon: <CsharpIcon /> },
        ],
      },
      {
        type: "Framework",
        details: [
          "NestJS",
          { name: "Express", icon: <ExpressIcon /> },
          "Strapi",
          { name: "Django", icon: <DjangoIcon /> },
          { name: "Flask", icon: <FlaskIcon /> },
          "FastAPI",
          "Laravel",
          "Echo",
        ],
      },
      {
        type: "Communication",
        details: [
          "RestAPI",
          { name: "GraphQL", icon: <GraphqlIcon /> },
          { name: "Apollo Server", icon: <ApolloIcon /> },
          "gRPC",
          "Protocol Buffer",
          "RabbitMQ",
        ],
      },
      {
        type: "Database",
        details: [
          { name: "MySQL", icon: <MysqlIcon /> },
          { name: "PostgreSQL", icon: <PostgresqlIcon /> },
          "MongoDB",
          "InfluxDB",
        ],
      },
      {
        type: "ORM",
        details: ["TypeORM", "Prisma", "Django ORM", "Eloquent ORM"],
      },
      {
        type: "Data Visualization",
        details: ["Tableau", "Grafana"],
      },
    ],
  },
  {
    category: "Cloud & DevOps",
    skills: [
      {
        type: "DevOps",
        details: [
          "Linux",
          "Nginx",
          { name: "Docker", icon: <DockerIcon /> },
          { name: "Github Action", icon: <GithubIcon /> },
          "Sentry",
          "Jenkins",
          "CircleCI",
          { name: "Kubernetes", icon: <KubernetesIcon /> },
        ],
      },
      {
        type: "Cloud",
        details: [
          { name: "AWS", icon: <AwsIcon /> },
          "Heroku",
          "Cloudflare",
          "Netlify",
          "iwinv",
        ],
      },
      {
        type: "AWS",
        details: [
          "EC2",
          "S3",
          "Route53",
          "RDS",
          "Elastic Beanstalk",
          "Cloudfront",
          "API Gateway",
          "ECR",
        ],
      },
    ],
  },
  {
    category: "Others",
    skills: [
      {
        type: "Human Language",
        details: [
          { name: "English", icon: <i>🇺🇸</i> },
          { name: "Japanese", icon: <i>🇯🇵</i> },
          { name: "Korean", icon: <i>🇰🇷</i> },
        ],
      },
      {
        type: "Hobbies",
        details: [
          { name: "Guitar", icon: <i>🎸</i> },
          { name: "Musical Composition", icon: <i>🎼</i> },
          { name: "Squash", icon: <i>🎾</i> },
        ],
      },
    ],
  },
];

const Skill = forwardRef(function SkillRoot(props, ref) {
  return (
    <Container ref={ref}>
      <h1>Skill Set</h1>
      <StackSet>
        {skillSet.map(({ category, skills }) => (
          <Category key={category}>
            <span>{category}</span>
            <ul>
              {skills.map((skill) => (
                <li key={skill.type}>
                  <Type>{skill.type}</Type>
                  <ul>
                    {skill.details.map((detail) =>
                      typeof detail === "string" ? (
                        <li style={{ marginLeft: "35px" }} key={detail}>
                          {detail}
                        </li>
                      ) : (
                        <li key={detail.name}>
                          <SkillBox>
                            {detail.icon}
                            <span>{detail.name}</span>
                          </SkillBox>
                        </li>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
          </Category>
        ))}
      </StackSet>
    </Container>
  );
});

export default Skill;
