import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import Box from "../components/Box";
import TopNav from "../components/TopNav";
import Artwork from "./Artwork";
import CV from "./CV";
import Home from "./Home";
import Intro from "./Intro";
import Portfolio from "./Portfolio";
import Skill from "./Skill";
import linkedinIcon from "../assets/icons/linkedin-30.png";
import githubIcon from "../assets/icons/github-30.png";

const Container = styled.div`
  height: inherit;

  --color: var(--green-color);
  --bg: var(--green-bg);
`;

const CRT = styled.div`
  /* min-width: 80ch;
  height: calc(80ch * 0.8); */
  font-size: max(1rem, 2.1vmin);
  height: 100vh;
  position: relative;
  background: var(--bg);
  background-image: radial-gradient(ellipse, var(--bg) 0%, var(--off) 90%);
  animation: on 2s linear;
  transition: all 0.5s;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--color);
`;

const Scanlines = styled.div`
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(18, 16, 16, 0) 50%,
    rgba(0, 0, 0, 0.25) 50%
  );
  background-size: 100vh 8px;
  z-index: 19;
  pointer-events: none;
`;

const Scanline = styled.div`
  width: 100%;
  height: 100px;
  z-index: 20;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.2) 10%,
    rgba(0, 0, 0, 0.1) 100%
  );
  opacity: 0.1;
  position: absolute;
  bottom: 100%;
  animation: scanline 8s linear infinite;

  @keyframes scanline {
    0% {
      bottom: 100%;
    }
    80% {
      bottom: 100%;
    }
    100% {
      bottom: 0%;
    }
  }
`;

const LinkBox = styled.div`
  position: absolute;
  margin: 40px;
  top: 0;
  right: 0;
`;

const Main = () => {
  const [show, setShow] = useState(false);

  const onClickMenu = () => {
    setShow((prev) => !prev);
  };

  const refHome = useRef();
  const refIntro = useRef();
  const refCV = useRef();
  const refSkill = useRef();
  const refPortfolio = useRef();
  const refArtwork = useRef();

  const refs = [refHome, refIntro, refCV, refSkill, refPortfolio, refArtwork];

  const scrollToPage = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickScreen = (e) => {
    if (e.target.id !== "nav" && e.target.tagName !== "CANVAS") {
      setShow(false);
    }
  };

  return (
    <Container>
      <Scanlines />
      <Scanline />
      <CRT id="crt-screen" onClick={onClickScreen}>
        <LinkBox>
          <a
            href="https://github.com/andy5090"
            target="_blank"
            rel="noreferrer"
          >
            <img src={githubIcon} alt="github" />
          </a>
          <a
            href="https://www.linkedin.com/in/andydklee/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedinIcon} alt="linkedin" />
          </a>
        </LinkBox>
        <Box onClick={onClickMenu} />
        <TopNav show={show} scrollToPage={scrollToPage} refs={refs} />
        <Home ref={refHome} />
        <Intro ref={refIntro} />
        <CV ref={refCV} />
        <Skill ref={refSkill} />
        <Portfolio ref={refPortfolio} />
        <Artwork ref={refArtwork} />
      </CRT>
    </Container>
  );
};

export default Main;
