import { forwardRef, useState } from "react";
import styled from "styled-components";
import artwork01 from "../assets/images/artwork01.png";
import artwork02 from "../assets/images/artwork02.png";
import artwork03 from "../assets/images/artwork03.png";
import artwork04 from "../assets/images/artwork04.jpg";
import artwork05 from "../assets/images/artwork05.jpg";
import artwork06 from "../assets/images/artwork06.jpg";
import artwork07 from "../assets/images/artwork07.jpg";
import CarouselView from "../components/Carousel";

const Container = styled.div`
  width: 80%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Minecraft;
  min-height: max(fit-content, 100%);

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  img {
    filter: grayscale(100%) opacity(50%);
    max-width: 100%;
    max-height: 100%;
    margin-bottom: -5px;
  }

  a {
    cursor: pointer;
    color: var(--green-color);
    :hover {
      color: #abfab0;
    }
  }
`;

const ProjectBoxSingle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 550px;

  > div {
    background-color: var(--green-color);
    height: fit-content;
    cursor: pointer;
  }

  margin: 30px 0;
`;

const Artwork = forwardRef(function ArtworkRoot(props, ref) {
  const [showCarousel, setShowCarousel] = useState(false);
  const [artworkId, setArtworkId] = useState(0);

  const onClickImageBox = (targetId) => {
    setShowCarousel(true);
    setArtworkId(targetId);
  };

  return (
    <>
      {showCarousel && (
        <CarouselView setShowCarousel={setShowCarousel} slideId={artworkId} />
      )}
      <Container ref={ref}>
        <h1>Artworks</h1>
        <h2>Mr.Lee Experiment</h2>
        <p>
          2009
          <br />
          Interactive art
          <br />
          Graduation Project as a team (Team MeltingPot)
          <br />
          Screen on Table with Interactive Device(Virtual Pipette)
          <br />
          <br />
          Exhibited at
          <br />
          2009 INDAF (Incheon, South Korea)
          <br />
          2009 SIGGRAPH Art Gallery (New Orleans, USA)
          <br />
          13th Japan Media Art Festival (Tokyo, Japan), Excellence Price
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(13)}>
            <img src={artwork04} alt="artwork04" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://www.youtube.com/watch?v=qwq26D2S_fk"
          target="_blank"
          rel="noreferrer">
          <h3>Archived Video</h3>
        </a>

        <h2>Aurora</h2>
        <p>
          2009
          <br />
          Interactive art
          <br />
          Distance sensor and motorized installation
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(14)}>
            <img src={artwork05} alt="artwork05" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://www.youtube.com/watch?v=PniUiAafUKI"
          target="_blank"
          rel="noreferrer">
          <h3>Archived Video</h3>
        </a>

        <h2>Imagen Sonidos</h2>
        <p>
          2013
          <br />
          Interactive visual sound art
          <br />
          Video projection, Sound generation by real-time video processing
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(10)}>
            <img src={artwork01} alt="artwork01" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://image-sound.andydklee.com"
          target="_blank"
          rel="noreferrer">
          <h3>Web Art Site</h3>
        </a>
        <a
          href="https://www.youtube.com/watch?v=XVqWjXCfyUc"
          target="_blank"
          rel="noreferrer">
          <h3>Archived Video</h3>
        </a>

        <h2>Man as Means</h2>
        <p>
          2015
          <br />
          Interactive art & Performance
          <br />
          Projection, Arcrylic Spray Paint
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(15)}>
            <img src={artwork06} alt="artwork06" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://www.youtube.com/watch?v=lBiUoK_1GtU"
          target="_blank"
          rel="noreferrer">
          <h3>Archived Video</h3>
        </a>
        <h2>Image Word Sound</h2>
        <p>
          2019
          <br />
          Interactive visual sound art
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(11)}>
            <img src={artwork02} alt="artwork02" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://imgwordsound.andydklee.com"
          target="_blank"
          rel="noreferrer">
          <h3>Web Art Site</h3>
        </a>
        <a
          href="https://www.youtube.com/watch?v=VrE3VzC1xzc"
          target="_blank"
          rel="noreferrer">
          <h3>Assorted Meaningless Data Conversion</h3>
        </a>

        <h2>Sentimental Sound</h2>
        <p>
          2019
          <br />
          Interactive visual sound art
          <br />
          Machine Learning, Sound generation by Sentient Model reading old time
          fictions.
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(12)}>
            <img src={artwork03} alt="artwork03" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://sentimental-sound.andydklee.com"
          target="_blank"
          rel="noreferrer">
          <h3>Web Art Site</h3>
        </a>

        <h2>The Interfering</h2>
        <p>
          2019
          <br />
          Interactive visual art & Performance
          <br />
          Machine Learning, Motorized Canvas and Black3.0 Paint
          <br />
          Canvas reacting by Sentient Model that reading old time fictions
          <br />
          Real-time video of Audience's facial emotion by motorized tracking
          camera.
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(16)}>
            <img src={artwork07} alt="artwork07" />
          </div>
        </ProjectBoxSingle>
        <a
          href="https://www.youtube.com/watch?v=u9tH5Uoysts"
          target="_blank"
          rel="noreferrer">
          <h3>Perfomance Video</h3>
        </a>
        <a
          href="https://www.youtube.com/watch?v=_sQTuAzTC4k"
          target="_blank"
          rel="noreferrer">
          <h3>Documentary Video</h3>
        </a>
      </Container>
    </>
  );
});

export default Artwork;
