import styled, { css } from "styled-components";
import { useAtom } from "jotai";
import { languageAtom } from "../atom/atom";

const Container = styled.div`
  left: 20%;
  position: fixed;
  top: 0;
  opacity: 0.85;

  transition: transform 1s cubic-bezier(0.7, 0, 0.3, 1);

  ${({ show }) =>
    show
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(-300px);
        `}

  @media (max-width: 900px) {
    ${({ show }) =>
      show
        ? css`
            transform: translateY(0);
          `
        : css`
            transform: translateY(-500px);
          `}
  }

  @media (max-width: 450px) {
    left: 0;
    top: 15%;
    ${({ show }) =>
      show
        ? css`
            transform: translateX(0);
          `
        : css`
            transform: translateX(-500px);
          `}
  }
  z-index: 5;
`;

const Card = styled.div`
  margin: 50px;
  padding: 20px 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: var(--green-color);

  --menu-color: var(--green-color);
  --pixel: 0.2rem;

  box-shadow: calc(var(--pixel) * -2) 0 0 0 var(--menu-color),
    calc(var(--pixel) * 2) 0 0 0 var(--menu-color),
    0 calc(var(--pixel) * -1) 0 var(--pixel) var(--menu-color),
    0 var(--pixel) 0 var(--pixel) var(--menu-color),
    0 calc(var(--pixel) * -4) 0 0 var(--menu-color),
    0 calc(var(--pixel) * 4) 0 0 var(--menu-color),
    0 calc(var(--pixel) * -6) 0 calc(var(--pixel) * -1) var(--menu-color),
    0 calc(var(--pixel) * 6) 0 calc(var(--pixel) * -1) var(--menu-color),
    0 calc(var(--pixel) * -8) 0 calc(var(--pixel) * -2) var(--menu-color),
    0 calc(var(--pixel) * 8) 0 calc(var(--pixel) * -2) var(--menu-color),
    0 calc(var(--pixel) * -11) 0 calc(var(--pixel) * -4) var(--menu-color),
    0 calc(var(--pixel) * 11) 0 calc(var(--pixel) * -4) var(--menu-color),
    0 calc(var(--pixel) * -14) 0 calc(var(--pixel) * -6) var(--menu-color),
    0 calc(var(--pixel) * 14) 0 calc(var(--pixel) * -6) var(--menu-color);
`;

const Button = styled.div`
  font-family: "Minecraft Bold";
  font-size: 2.2rem;
  color: black;
  border: 4px solid black;
  padding: 10px;
  cursor: pointer;
`;

function TopNav({ show, scrollToPage, refs }) {
  const onClickButton = (number) => {
    scrollToPage(refs[number]);
  };

  const [language, setLanguage] = useAtom(languageAtom);

  const onClickLocale = () => {
    if (language === "Eng") {
      setLanguage("Kor");
    } else {
      setLanguage("Eng");
    }
  };

  return (
    <Container id="nav" show={show}>
      <Card id="nav">
        <Button onClick={() => onClickButton(0)}>Andy</Button>
        <Button onClick={() => onClickButton(1)}>Intro</Button>
        <Button onClick={() => onClickButton(2)}>CV</Button>
        <Button onClick={() => onClickButton(3)}>SkillSet</Button>
        <Button onClick={() => onClickButton(4)}>Projects</Button>
        <Button onClick={() => onClickButton(5)}>Artworks</Button>
        <Button onClick={onClickLocale}>
          {language === "Eng" ? "Kor" : "Eng"}
        </Button>
      </Card>
    </Container>
  );
}

export default TopNav;
