import { forwardRef, useState } from "react";
import styled from "styled-components";
import slide_01 from "../assets/images/slide01.jpg";
import slide_02 from "../assets/images/slide02.jpg";
import slide_03 from "../assets/images/slide03.jpg";
import slide_04 from "../assets/images/slide04.jpg";
import slide_05 from "../assets/images/slide05.jpg";
import slide_06 from "../assets/images/slide06.jpg";
import slide_07 from "../assets/images/slide07.jpg";
import slide_08 from "../assets/images/slide08.jpg";
import slide_09 from "../assets/images/slide09.jpg";
import slide_10 from "../assets/images/slide10.jpg";
import slide_11 from "../assets/images/slide11.jpg";
import slide_12 from "../assets/images/slide12.jpg";
import slide_13 from "../assets/images/slide13.jpg";
import slide_14 from "../assets/images/slide14.jpg";
import slide_15 from "../assets/images/slide15.jpg";
import slide_16 from "../assets/images/slide16.jpg";
import slide_17 from "../assets/images/slide17.jpg";
import slide_18 from "../assets/images/slide18.jpg";
import slide_19 from "../assets/images/slide19.jpg";
import slide_20 from "../assets/images/slide20.jpg";
import slide_21 from "../assets/images/slide21.jpg";
import slide_22 from "../assets/images/slide22.jpg";
import slide_23 from "../assets/images/slide23.jpg";
import slide_24 from "../assets/images/slide24.jpg";

import CarouselView from "../components/Carousel";

const Container = styled.div`
  width: 80%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Minecraft;
  min-height: max(fit-content, 100%);

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  img {
    filter: grayscale(100%) opacity(50%);
    max-width: 100%;
    max-height: 100%;
    margin-bottom: -5px;
  }

  @media (max-width: 450px) {
    img {
      width: 100%;
    }

    > div {
      max-width: 100%;
    }
  }

  a {
    cursor: pointer;
    color: var(--green-color);
    :hover {
      color: #abfab0;
    }
  }
`;

const ProjectBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  max-width: 90%;

  > div {
    background-color: var(--green-color);
    height: fit-content;
    cursor: pointer;
  }

  margin: 30px 0;
`;

const ProjectBoxSingle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 550px;

  > div {
    background-color: var(--green-color);
    height: fit-content;
    cursor: pointer;
  }
  margin: 30px 0;
`;

const Portfolio = forwardRef(function PortfolioRoot(props, ref) {
  const [showCarousel, setShowCarousel] = useState(false);
  const [projectId, setProjectId] = useState(0);

  const onClickImageBox = (targetId) => {
    setShowCarousel(true);
    setProjectId(targetId);
  };

  return (
    <>
      {showCarousel && (
        <CarouselView setShowCarousel={setShowCarousel} slideId={projectId} />
      )}
      <Container ref={ref}>
        <h1>Projects</h1>
        <h2>History Share - Timeline sharing and combining tool</h2>
        <a href="https://history-share.xyz" target="_blank" rel="noreferrer">
          <h3>History Share</h3>
        </a>
        <p>
          - Similar to Kronoli but fucused on history.
          <br /> - T3 Stack (NextJS based)
        </p>

        <ProjectBox />
        <h2>Carbon Data Lab - Carbonnote</h2>
        <p>
          - Carbon emission reduction related Consulting inquiries collecting
          and Simulation software providing.
          <br /> - Laravel with Blade template and React
          <br /> - Admin pages are seperated and created by React
        </p>
        <ProjectBox>
          <div onClick={() => onClickImageBox(5)}>
            <img src={slide_23} alt="slide_23" />
          </div>
          <div onClick={() => onClickImageBox(5)}>
            <img src={slide_21} alt="slide_21" />
          </div>
          <div onClick={() => onClickImageBox(5)}>
            <img src={slide_22} alt="slide_22" />
          </div>
          <div onClick={() => onClickImageBox(5)}>
            <img src={slide_24} alt="slide_24" />
          </div>
        </ProjectBox>
        <h2>AIDKR - Backoffice Memebership Management Center</h2>
        <p>
          - CRM like Farm membership and information management system
          <br /> - Farm Information DataGrid, Checklist, Service Equipment
          Installation Process, Events and etc
          <br /> - AIDKR Google Workspace User only
          <br /> - Redirection to other backoffice service by query with token
          redirection
        </p>
        <ProjectBox>
          <div onClick={() => onClickImageBox(0)}>
            <img src={slide_01} alt="slide_01" />
          </div>
          <div onClick={() => onClickImageBox(0)}>
            <img src={slide_02} alt="slide_02" />
          </div>
          <div onClick={() => onClickImageBox(0)}>
            <img src={slide_03} alt="slide_03" />
          </div>
        </ProjectBox>
        <h2>AIDKR - Backoffice Live Admin ICT Manager</h2>
        <p>
          - Entity Management System
          <br /> - To register ICT Equipment Type and specification for
          connecting
          <br /> - Using Provided API from ICT manufacturer to add or update
          newrly added or changed ICT Equipment
          <br /> - Ported RBAC of Django to NestJS
          <br /> - Using React and Material UI kit for Front-end
        </p>
        <ProjectBoxSingle>
          <div onClick={() => onClickImageBox(1)}>
            <img src={slide_04} alt="slide_04" />
          </div>
        </ProjectBoxSingle>
        <h2>AIDKR - Backoffice App Manager</h2>
        <p>
          - NestJS with MSA
          <br /> - Using gRPC for communication between microservices and API
          gateway
          <br /> - Using Swagger with very detailed described API for
          documentation
          <br /> - Ported Django RBAC to NestJS (PBKDF2 Hashing)
          <br /> - FCM token management and Push API for Push notification
          <br /> - Notice Management
          <br /> - Notification Management and manual push support
          <br /> - Using React and CreativeTim Argon Template for Admin
          Front-end
        </p>
        <ProjectBox>
          <div onClick={() => onClickImageBox(2)}>
            <img src={slide_05} alt="slide_05" />
          </div>
          <div onClick={() => onClickImageBox(2)}>
            <img src={slide_06} alt="slide_06" />
          </div>
          <div onClick={() => onClickImageBox(2)}>
            <img src={slide_07} alt="slide_07" />
          </div>
          <div onClick={() => onClickImageBox(2)}>
            <img src={slide_08} alt="slide_08" />
          </div>
          <div onClick={() => onClickImageBox(2)}>
            <img src={slide_09} alt="slide_09" />
          </div>
        </ProjectBox>
        <h2>{`Kronoli - Timeline mixing & sharing service`}</h2>
        <p>
          - Express server with GraphQL
          <br /> - PostgreSQL and Prisma ORM
          <br /> - Flask server for images scrapping
          <br /> - React and Styled-components for Front-end
        </p>
        <ProjectBox>
          <div onClick={() => onClickImageBox(3)}>
            <img src={slide_10} alt="slide_10" />
          </div>
          <div onClick={() => onClickImageBox(3)}>
            <img src={slide_11} alt="slide_11" />
          </div>
          <div onClick={() => onClickImageBox(3)}>
            <img src={slide_12} alt="slide_12" />
          </div>
          <div onClick={() => onClickImageBox(3)}>
            <img src={slide_13} alt="slide_13" />
          </div>
          <div onClick={() => onClickImageBox(3)}>
            <img src={slide_14} alt="slide_14" />
          </div>
        </ProjectBox>
        <h2>Alcohol Timer App</h2>
        <p>
          - React Native with Redux Persist
          <br /> - Google AdMob implemented
          <br /> - Used to be on Google Play but now the App is not available
          anymore
        </p>
        <ProjectBox>
          <div onClick={() => onClickImageBox(4)}>
            <img src={slide_15} alt="slide_15" />
          </div>
          <div onClick={() => onClickImageBox(4)}>
            <img src={slide_16} alt="slide_16" />
          </div>
          <div onClick={() => onClickImageBox(4)}>
            <img src={slide_17} alt="slide_17" />
          </div>
          <div onClick={() => onClickImageBox(4)}>
            <img src={slide_18} alt="slide_18" />
          </div>
          <div onClick={() => onClickImageBox(4)}>
            <img src={slide_19} alt="slide_19" />
          </div>
          <div onClick={() => onClickImageBox(4)}>
            <img src={slide_20} alt="slide_20" />
          </div>
        </ProjectBox>
      </Container>
    </>
  );
});

export default Portfolio;
