import { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useCursor } from "@react-three/drei";

import styled from "styled-components";
import { EffectComposer, Pixelation } from "@react-three/postprocessing";
import { useState } from "react";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 20%;
  z-index: 5;

  @media (max-width: 450px) {
    width: 30%;
  }
`;

function Box({ onClick }) {
  return (
    <Container>
      <Canvas>
        <EffectComposer>
          <Pixelation granularity={3} />
        </EffectComposer>
        <ambientLight intensity={0.1} />
        <pointLight position={[-100, 50, 100]} intensity={0.8} />
        <MenuBox onClick={onClick} />
      </Canvas>
    </Container>
  );
}

export default Box;

function MenuBox(props) {
  const ref = useRef();
  const [hovered, hover] = useState(false);
  useFrame((state, delta) => (ref.current.rotation.y += 0.01));
  useCursor(hovered);

  const darkGreen = "#31f540";
  const lightGreen = "#abf8b0";

  return (
    <mesh
      ref={ref}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
      {...props}>
      <mesh position={[0, 1, 0]}>
        <boxGeometry args={[3, 0.5, 3]} />
        <meshStandardMaterial color={hovered ? lightGreen : darkGreen} />
      </mesh>
      <mesh>
        <boxGeometry args={[3, 0.5, 3]} />
        <meshStandardMaterial color={hovered ? lightGreen : darkGreen} />
      </mesh>
      <mesh position={[0, -1, 0]}>
        <boxGeometry args={[3, 0.5, 3]} />
        <meshStandardMaterial color={hovered ? lightGreen : darkGreen} />
      </mesh>
    </mesh>
  );
}
