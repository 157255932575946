/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useMemo, useState } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { AsciiEffect } from "three-stdlib";
import styled from "styled-components";
import { AndyHeadModel } from "./AndyHeadModel";

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  aspect-ratio: 0.8 / 1;
`;

function AndyHead() {
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setReverse((prev) => !prev);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Canvas>
        <AsciiRenderer invert />
        <pointLight position={[120, 60, 200]} intensity={3} />
        <OrbitControls
          enableZoom={false}
          minAzimuthAngle={-0.3}
          maxAzimuthAngle={1}
          maxPolarAngle={1.5}
          minPolarAngle={1.5}
          autoRotate
          reverseOrbit={reverse}
        />
        <Suspense fallback={null}>
          <AndyHeadModel
            position={[0, -0.6, 0]}
            rotation={[0.35, 0.4, 0]}
            scale={0.017}
          />
        </Suspense>
      </Canvas>
    </Container>
  );
}

export default AndyHead;

function AsciiRenderer({
  renderIndex = 1,
  characters = "@%$#?*+=-:,. ",
  ...options
}) {
  const { size, gl, scene, camera } = useThree();

  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, options);
    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "0px";
    effect.domElement.style.left = "0px";
    effect.domElement.style.color = "#5bf870";
    effect.domElement.style.backgroundColor = "#05321e";
    effect.domElement.style.pointerEvents = "none";
    return effect;
  }, [characters, options.invert]);

  useEffect(() => {
    gl.domElement.parentNode.appendChild(effect.domElement);
    return () => gl.domElement.parentNode.removeChild(effect.domElement);
  }, [effect]);

  useEffect(() => {
    effect.setSize(size.width, size.height);
  }, [effect, size]);

  useFrame((state) => {
    effect.render(scene, camera);
  }, renderIndex);

  return null;
}
