import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import slide_01 from "../assets/images/slide01.jpg";
import slide_02 from "../assets/images/slide02.jpg";
import slide_03 from "../assets/images/slide03.jpg";
import slide_04 from "../assets/images/slide04.jpg";
import slide_05 from "../assets/images/slide05.jpg";
import slide_06 from "../assets/images/slide06.jpg";
import slide_07 from "../assets/images/slide07.jpg";
import slide_08 from "../assets/images/slide08.jpg";
import slide_09 from "../assets/images/slide09.jpg";
import slide_10 from "../assets/images/slide10.jpg";
import slide_11 from "../assets/images/slide11.jpg";
import slide_12 from "../assets/images/slide12.jpg";
import slide_13 from "../assets/images/slide13.jpg";
import slide_14 from "../assets/images/slide14.jpg";
import slide_15 from "../assets/images/slide15.jpg";
import slide_16 from "../assets/images/slide16.jpg";
import slide_17 from "../assets/images/slide17.jpg";
import slide_18 from "../assets/images/slide18.jpg";
import slide_19 from "../assets/images/slide19.jpg";
import slide_20 from "../assets/images/slide20.jpg";
import slide_21 from "../assets/images/slide21.jpg";
import slide_22 from "../assets/images/slide22.jpg";
import slide_23 from "../assets/images/slide23.jpg";
import slide_24 from "../assets/images/slide24.jpg";

import artwork01 from "../assets/images/artwork01.png";
import artwork01_01 from "../assets/images/artwork01_01.jpg";
import artwork01_02 from "../assets/images/artwork01_02.jpg";
import artwork01_03 from "../assets/images/artwork01_03.jpg";
import artwork01_04 from "../assets/images/artwork01_04.jpg";
import artwork01_05 from "../assets/images/artwork01_05.jpg";
import artwork01_06 from "../assets/images/artwork01_06.jpg";
import artwork02 from "../assets/images/artwork02.png";
import artwork03 from "../assets/images/artwork03.png";
import artwork04 from "../assets/images/artwork04.jpg";
import artwork04_01 from "../assets/images/artwork04_01.jpg";
import artwork04_02 from "../assets/images/artwork04_02.jpg";
import artwork04_03 from "../assets/images/artwork04_03.jpg";
import artwork04_04 from "../assets/images/artwork04_04.jpg";
import artwork04_05 from "../assets/images/artwork04_05.jpg";
import artwork05 from "../assets/images/artwork05.jpg";
import artwork06 from "../assets/images/artwork06.jpg";
import artwork06_01 from "../assets/images/artwork06_01.jpg";
import artwork06_02 from "../assets/images/artwork06_02.jpg";
import artwork06_03 from "../assets/images/artwork06_03.jpg";
import artwork06_04 from "../assets/images/artwork06_04.jpg";
import artwork06_05 from "../assets/images/artwork06_05.jpg";
import artwork06_06 from "../assets/images/artwork06_06.jpg";
import artwork06_07 from "../assets/images/artwork06_07.jpg";
import artwork06_08 from "../assets/images/artwork06_08.jpg";
import artwork07 from "../assets/images/artwork07.jpg";
import artwork07_01 from "../assets/images/artwork07_01.jpg";
import artwork07_02 from "../assets/images/artwork07_02.jpg";
import artwork07_03 from "../assets/images/artwork07_03.jpg";
import artwork07_04 from "../assets/images/artwork07_04.jpg";
import artwork07_05 from "../assets/images/artwork07_05.jpg";
import artwork07_06 from "../assets/images/artwork07_06.jpg";
import artwork07_07 from "../assets/images/artwork07_07.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect, useState } from "react";

const slideGroups = [
  {
    groupId: 0,
    projectName: "한국축산데이터 백오피스 회원관리센터",
    imagePaths: [slide_01, slide_02, slide_03],
  },
  {
    groupId: 1,
    projectName: "한국축산데이터 백오피스 라이브 어드민 ICT 매니져",
    imagePaths: [slide_04],
  },
  {
    groupId: 2,
    projectName: "한국축산데이터 백오피스 앱매니져",
    imagePaths: [slide_05, slide_06, slide_07, slide_08, slide_09],
  },
  {
    groupId: 3,
    projectName: "크로놀리 - 타임라인 믹싱 공유 서비스",
    imagePaths: [slide_10, slide_11, slide_12, slide_13, slide_14],
  },
  {
    groupId: 4,
    projectName: "알콜타이머 앱",
    imagePaths: [slide_15, slide_16, slide_17, slide_18, slide_19, slide_20],
  },
  {
    groupId: 5,
    projectName: "카본데이터랩 카본노트",
    imagePaths: [slide_23, slide_21, slide_22, slide_24],
  },
  {
    groupId: 10,
    projectName: "Imagen Sonidos",
    imagePaths: [
      artwork01,
      artwork01_01,
      artwork01_02,
      artwork01_03,
      artwork01_04,
      artwork01_05,
      artwork01_06,
    ],
  },
  {
    groupId: 11,
    projectName: "Image Word Sound",
    imagePaths: [artwork02],
  },
  {
    groupId: 12,
    projectName: "Sentimental Sound",
    imagePaths: [artwork03],
  },
  {
    groupId: 13,
    projectName: "Mr.Lee Experiment",
    imagePaths: [
      artwork04,
      artwork04_01,
      artwork04_02,
      artwork04_03,
      artwork04_04,
      artwork04_05,
    ],
  },
  {
    groupId: 14,
    projectName: "Aurora",
    imagePaths: [artwork05],
  },
  {
    groupId: 15,
    projectName: "Man as Means",
    imagePaths: [
      artwork06,
      artwork06_01,
      artwork06_02,
      artwork06_03,
      artwork06_04,
      artwork06_05,
      artwork06_06,
      artwork06_07,
      artwork06_08,
    ],
  },
  {
    groupId: 16,
    projectName: "The Interfering",
    imagePaths: [
      artwork07,
      artwork07_01,
      artwork07_02,
      artwork07_03,
      artwork07_04,
      artwork07_05,
      artwork07_06,
      artwork07_07,
    ],
  },
];

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.7);
`;

function CarouselView({ setShowCarousel, slideId }) {
  const [slideGroup, setSlideGroup] = useState(null);

  useEffect(() => {
    setSlideGroup(slideGroups.find((slide) => slide.groupId === slideId));
  }, [slideId]);

  const onClickOveraly = (e) => {
    if (e.target.id === "overlay") {
      setShowCarousel(false);
    }
  };

  return (
    <Container id="overlay" onClick={onClickOveraly}>
      <Carousel showThumbs showStatus showArrows>
        {slideGroup &&
          slideGroup.imagePaths.map((imagePath, index) => (
            <div key={`${slideGroup.groupId}_${index}`}>
              <img src={imagePath} alt="project01-1" />
              <p className="legend">{slideGroup.projectName}</p>
            </div>
          ))}
      </Carousel>
    </Container>
  );
}

export default CarouselView;
