import React, { useRef, useState } from "react";
import { useGLTF, useCursor } from "@react-three/drei";

export function AndyHeadModel(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/andy_head_model.glb");
  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}>
      <mesh
        geometry={nodes.ArcsoftMeshes0_1.geometry}
        material={materials["Material.001"]}
      />
      <mesh
        geometry={nodes.ArcsoftMeshes0_2.geometry}
        material={nodes.ArcsoftMeshes0_2.material}
      />
    </group>
  );
}

useGLTF.preload("/andy_head_model.glb");
