import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import MinecraftFont from "../assets/fonts/MinecraftRegular.otf";
import MinecraftBoldFont from "../assets/fonts/MinecraftBold.otf";
import SourceCodeProFont from "../assets/fonts/SourceCodePro.ttf";
import DOSGothic from "../assets/fonts/DOSGothic.ttf";

const GlobalStyle = createGlobalStyle`
    ${reset};    

    * {
        box-sizing:border-box;
    }
    html {
        height: 100vh;        
    }
    body {
        font-family: Lucida Console, Courier New, Courier, monospace;
        overflow: hidden;
        height: 100vh;
        background-color: black;

        @font-face {
            font-family: Minecraft;
            src: local("Minecraft"), url(${MinecraftFont}) format("opentype");
            font-weight: normal;
        }

        @font-face {
            font-family: "Minecraft Bold";
            src: local("Minecraft Bold"), url(${MinecraftBoldFont}) format("opentype");
            font-weight: bold;
        }

        @font-face {
            font-family: "Source Code Pro";
            src: local("Source Code Pro"), url(${SourceCodeProFont}) format("truetype");
            font-weight: normal;
        }

        @font-face {
            font-family: 'DOSGothic';
            src: url(${DOSGothic}) format('truetype');
            font-weight: normal;
            font-style: normal;        
        }
      
  }
    }

    #root {
        height: inherit;        

        --green-color: #5bf870;
	    --green-bg: #05321e;
	    --red-color: #c0100a;
	    --red-bg: #1b0302;
	    --blue-color: #a5a5ff;
	    --blue-bg: #4242e7;
	    --off: #050505;
	    --dark: #111;
	    --term: "> ";
    }
    
`;

export default GlobalStyle;
