import { forwardRef } from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import { languageAtom } from "../atom/atom";

const Container = styled.div`
  width: 70%;
  padding-top: 10%;
  font-size: 1.5rem;
  line-height: ${(props) => (props.locale === "Eng" ? "2rem" : "2.5rem")};
  min-height: max(fit-content, 100%);
  font-family: ${(props) =>
    props.locale === "Eng" ? "Minecraft" : "DOSGothic"};

  pre {
    white-space: pre-wrap;
  }

  @media (max-width: 1600px) {
    line-height: 1.8rem;
    font-size: 1.3rem;
  }

  @media (max-width: 1000px) {
    line-height: 1.5rem;
    font-size: 1rem;
  }
`;

const Intro = forwardRef(function IntroRoot(props, ref) {
  const [language] = useAtom(languageAtom);

  return (
    <Container {...props} ref={ref} locale={language}>
      {language === "Eng" ? (
        <pre>{`
"Business oriented and Communication based product delivery"
is my goal as a full-stack software engineer.

While working for 6 years as a telecom network (LTE) engineer at Samsung Electronics, I had a chance to experience data engineering, analysis and BI engineering.
Product is from business. There are both sides needed, the backend and the frontend. Full-stack development gives me a picture of the entire service structure more clearly.        
In terms of development methodology, I was influenced by XP, Agile, Pragmatic programmer, Martin Fowler's Refactoring, Clean Code, TDD, Domain Driven Design, etc.
I also have interest about cutting edge technologies like AI and Block-chain. And especially I studied AI for a year at MODU research center the private institution of AI research center in South Korea.        
Aside from my curiosity about technology, I am very interested in effectively delivering the value of the service itself to users. I used to focus on providing practical and meaningful products to users by harmonizing UX, marketing, and development process.        
Apart from development, I think about the world. I have a lot of interest in humanities and history, and I am interested in all kinds of arts including music and painting that will enrich my life. When I have time, I work on media art. And sometimes I play the guitar and make music.
For books, I really enjoyed reading Homo Deus by Yuval Harari.        
I tried few times for starting my own business with my own ideas. Therefore, I am very interested in not only development but also Lean startup, Growth hacking, Design thinking and Branding, and read business management-related books such as financial statement analysis, OKR and High Output Management for the future.        
In an aspect of service, I am greatly influenced by Peter Thiel's Zero to One, and therefore I'm pursuing creative monopolies rather than disruptive innovation.
    `}</pre>
      ) : (
        <pre>{`
비즈니스 중심 그리고 소통 지향 방식으로 서비스 프로덕트 제공을 추구하는 풀스택 개발자

삼성전자에서 무선망 네트워크(LTE) 엔지니어로 6년간의 커리어를 쌓았고 당시에는 LTE망에 대한 테스트로그 분석, 기지국 통계 데이터 분석 등을 주로 수행했습니다.
이 과정에서 C# .NET 기반으로 사내 어플리케이션 개발, 타블로와 MySQL을 이용한 BI 엔지니어링을 수행했던 경험이 있습니다.        
서비스의 본질은 비즈니스에 있고 그러한 서비스를 제공하는데 있어 백엔드냐 프론트엔드냐 하는 고민은 무의미 할 것입니다.
두 가지를 같이 고려 할 수 있어야하고 서비스의 전체적인 그림을 그릴 수 있어야 할 것입니다. 따라서 풀스택 개발 + 약간의 DevOps를 추구하게 되었습니다.        
개발방법론적으로는 XP, 애자일, 실용주의 프로그래머, 마틴파울러의 리펙토링, TDD, CleanCode, 도메인주도개발 등의 영향을 받았습니다.
AI, Block-chain과 관련해서도 기술적으로 스터디를 꾸준히 하고 있습니다. 특히 AI의 경우는 민간연구소인 모두의 연구소에서 약 1년간 Deep Learning College(3기)를 수료하면서 학습하였습니다.        
기술에 대한 호기심과는 별개로 서비스 자체의 가치가 사용자에게 효과적으로 전달되는 것에 큰 관심이 있습니다. UX, 마케팅, 개발 프로세스가 잘 어우러져 사용자에게 좀 더 실질적이고 의미 있는 제품을 제공하는 것에 집중합니다.        
개인적으로 세상에 대해 고민합니다. 인문, 역사 등에 관심이 많으며 인생을 풍요롭게 해줄 음악, 미술 등 예술 전반에 대해 관심이 있습니다. 때때로 예술(미디어아트) 작업을 하고 기타를 치며 음악을 만듭니다. 책중에서는 유발하라리의 호모데우스를 제일 좋아하는 책 중의 하나로 꼽습니다.        
스스로의 아이디어로 창업을 시도하기도 했습니다. 따라서 개발 뿐만 아니라 린스타트업, 그로스해킹, 디자인씽킹, 브랜딩 등에도 큰 관심이 있고 향후를 위해 재무제표 분석, OKR, High Output Management 등과 같은 경영 관련 서적을 읽고는 합니다.        
서비스적인 측면에서 피터틸의 Zero to One에서 큰 영향을 받았습니다. 따라서 파괴적인 혁신보다는 실용적이고 독창성을 가진 독점적 가치를 추구합니다.
    `}</pre>
      )}
    </Container>
  );
});

export default Intro;
